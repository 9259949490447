import React, { useState } from 'react';
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Seo from '../components/Seo';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';

import * as styles from './project-template.module.css'

SwiperCore.use([Navigation, Pagination, Thumbs]);


export default ({data}) => {

    const [thumbsSwiper, setThumbsSwiper] = useState();
    function getPlan(plan) {
        const options = {
            settings: {
              overlayColor: "rgba(214, 214, 214)",
            },
            buttons: {
                backgroundColor: 'rgba(30,30,36,0.8)',
                iconColor: 'rgba(255, 255, 255, 0.8)',
                iconPadding: '10px',
                showAutoplayButton: false,
                showCloseButton: true,
                showDownloadButton: false,
                showFullscreenButton: false,
                showNextButton: false,
                showPrevButton: false,
                showThumbnailsButton: false,
                size: '40px'
            },
            thumbnails: {
                showThumbnails: false
            },
            disableWheelControls: true,
            disableKeyboardControls: true,
            // disablePanzoom: true,
            disablePanzoom: true,
            hideControlsAfter: false,
            caption: {
              captionColor: "#101110",
            //   captionTextTransform: "uppercase",
            }
          };
        // return plan ? <Img fluid={plan.fluid} alt={plan.title}></Img> : '';
        return plan ? 
        // <a className={styles.plan} href={plan.file.url} target="_blank" rel="noreferrer"><img  src={plan.file.url} alt='project plan'/></a> 
        <div className={styles.plan}>
        <SimpleReactLightbox>
            <SRLWrapper options={options}>
                <a href={plan.file.url}>
                    <img src={plan.file.url} alt={data.contentfulProject.planTitle} />
                </a>
            </SRLWrapper>
        </SimpleReactLightbox>
        </div>
        
        : '';
    }
    return (    
        <Layout>
            <Seo 
                title = {data.contentfulProject.title}
                description = {data.contentfulProject.description.description}
                image = {data.contentfulProject.heroImage.file.url}
            />
            <div className={styles.wrapper}>
                <div className={styles.swiperWrapper}>
                    <Swiper
                        // id="main"
                        thumbs={{swiper: thumbsSwiper}}
                        className={styles.mainSwiper}
                        tag="section"
                        wrapperTag="div"
                        navigation
                        spaceBetween={0}
                        slidesPerView={1}
                        // onInit={(swiper) => console.log('Swiper initialized!', swiper)}
                        // onSlideChange={(swiper) => {console.log('Slide index changed to:', swiper.activeIndex)}}
                        // onReachEnd = {() => console.log('Swiper end reached')}
                        loop
                        >
                        {
                            data.contentfulProject.images.map((image, index) => {
                                return (
                                    <SwiperSlide 
                                        key={`slide-${index}`}
                                        className={styles.slideLi}
                                        tag="li"
                                    >
                                        <Img className={styles.img} fluid={image.fluid}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <Swiper 
                        // id='thumbs' 
                        className={styles.thumbsWrapper}
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={3}
                        loop
                    >
                        {
                            data.contentfulProject.images.map((image, index) => {
                                return (
                                    <SwiperSlide 
                                        key={`slide-${index}`}
                                        className={styles.slideLi}
                                        tag="li"
                                    >
                                        <Img className={styles.thumb} fluid={image.fluid}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
           
            <div className={styles.descriptionWrapper}>
                <div>
                    <h1 className={styles.title}>{data.contentfulProject.title}</h1>
                    <p className={styles.year}>{data.contentfulProject.year}</p>
                    <p className={styles.description} dangerouslySetInnerHTML={{__html: data.contentfulProject.description.childMarkdownRemark.html}}/>
                </div>
                        
                    {getPlan(data.contentfulProject.plan)}

            </div>
           
            </div>
        </Layout>
    )       
}

export const projectQuery = graphql`
    query projectBySlug($slug: String!) {
        contentfulProject(slug: { eq: $slug }) {
            title
            slug
            year
            description {
                description
                childMarkdownRemark {
                    html
                }
            }
            images {
                fluid(maxWidth: 1000) {
                    ...GatsbyContentfulFluid_tracedSVG
                }
            }
            planTitle
            plan {
                file {
                    url
                }
                fluid(maxWidth: 800) {
                    ...GatsbyContentfulFluid_tracedSVG
                }
                title
            }
            heroImage {
                file {
                    url
                }
            }

        }
    }
`;